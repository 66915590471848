<template>
    <div id="login-page">
        <div id="billboard">
            <div class="logo-wrapper">
                <img :src="getLogoUrl()" class="img-fluid" alt="" />
            </div>
            <div class="baseline-wrapper">
                <img :src="getBaselineUrl()" class="img-fluid" alt="" />
            </div>
        </div>
        <b-container id="login-form">
            <h1 class="text-white">Création du compte</h1>
            <b-spinner
                v-if="load"
                type="grow"
                variant="danger"
                class="mt-5"
            ></b-spinner>
            <!-- STEP 1 -->
            <b-form
                novalidate
                class="mt-3 mb-3 w-100"
                @submit="submit1"
                v-if="!load && step === 1"
            >
                <b-form-row class="justify-content-center">
                    <b-col sm="6" lg="5" xl="4">
                        <b-form-group>
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        autocomplete="off"
                                        type="text"
                                        placeholder="Téléphone"
                                        v-model="form.telephone"
                                        :state="formValidation.telephone"
                                        aria-invalid="login-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="login-error">
                                        Veuillez saisir votre numéro de
                                        téléphone
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        type="text"
                                        autocomplete="off"
                                        placeholder="Code Pointage"
                                        v-model="form.code_domatel"
                                        :state="formValidation.code_domatel"
                                        aria-invalid="pwd-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="pwd-error">
                                        Veuillez saisir le code pointage
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                            </b-list-group>
                        </b-form-group>
                        <b-button
                            variant="danger"
                            size="lg"
                            class="align-items-center d-flex justify-content-center w-100"
                            type="submit"
                        >
                            <span class="align-items-center d-flex"
                                ><i class="fa fa-sign-in-alt fas mr-3"></i>
                                Valider</span
                            >
                        </b-button>
                        <div class="w-100 text-center mt-3">
                            <router-link class="text-white" to="/login">
                                Se connecter</router-link
                            >
                        </div>
                    </b-col>
                </b-form-row>
            </b-form>
            <!-- STEP 2 -->
            <b-form
                novalidate
                class="mt-3 mb-3 w-100"
                @submit="submit2"
                v-if="!load && step === 2"
            >
                <b-form-row class="justify-content-center">
                    <b-col
                        sm="6"
                        lg="5"
                        xl="4"
                        class="text-white mb-3 text-center"
                    >
                        Vous allez recevoir un sms qui contient le code demandé.
                    </b-col>
                    <b-col sm="6" lg="5" xl="4">
                        <b-form-group>
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        autocomplete="off"
                                        type="text"
                                        placeholder="Code de vérification"
                                        v-model="form.code_sms"
                                        :state="formValidation.code_sms"
                                        aria-invalid="login-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="login-error">
                                        Veuillez saisir le code de vérification
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                            </b-list-group>
                        </b-form-group>
                        <b-button
                            id="sendSms"
                            size="lg"
                            class="align-items-center d-flex justify-content-center w-100 mb-3"
                            @click="sendVerificationCode"
                        >
                            <span class="align-items-center d-flex"
                                ><i class="fas fa-sms fas mr-3 fa-2x"></i>
                                Envoyer le sms de nouveau</span
                            >
                        </b-button>
                        <b-button
                            variant="danger"
                            size="lg"
                            class="align-items-center d-flex justify-content-center w-100"
                            type="submit"
                        >
                            <span class="align-items-center d-flex"
                                ><i class="fa fa-sign-in-alt fas mr-3"></i>
                                Valider</span
                            >
                        </b-button>
                    </b-col>
                </b-form-row>
            </b-form>
            <!-- STEP 3 -->
            <b-form
                novalidate
                class="mt-3 mb-3 w-100"
                @submit="submit3"
                v-if="!load && step === 3"
            >
                <b-form-row class="justify-content-center">
                    <b-col sm="6" lg="5" xl="4">
                        <b-form-group>
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        type="password"
                                        autocomplete="new-password"
                                        placeholder="Mot de passe"
                                        v-model="form.password"
                                        :state="formValidation.password"
                                        aria-invalid="pwd-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="pwd-error">
                                        Veuillez saisir le mot de passe (6
                                        caractères minimum)
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <b-form-input
                                        class="form-control-lg border-0"
                                        type="password"
                                        autocomplete="new-password"
                                        placeholder="Confirmation du mot de passe"
                                        v-model="form.password_confirmation"
                                        :state="
                                            formValidation.password_confirmation
                                        "
                                        aria-invalid="pwd-error"
                                        :required="true"
                                    />
                                    <b-form-invalid-feedback id="pwd-error">
                                        Veuillez saisir la confirmation du mot
                                        de passe
                                    </b-form-invalid-feedback>
                                </b-list-group-item>
                            </b-list-group>
                        </b-form-group>
                        <b-button
                            variant="danger"
                            size="lg"
                            class="align-items-center d-flex justify-content-center w-100"
                            type="submit"
                        >
                            <span class="align-items-center d-flex"
                                ><i class="fa fa-sign-in-alt fas mr-3"></i>
                                Valider</span
                            >
                        </b-button>
                    </b-col>
                </b-form-row>
            </b-form>

            <!-- STEP 4 -->
            <b-col
                sm="12 text-white text-center mt-3"
                v-if="!load && step === 4"
            >
                <p>
                    Votre compte a bien été enregistré. Vous pouvez dès à
                    présent vous connecter à l'application.
                </p>
                <p>
                    Vous serez rediriger dans 10 secondes vers le formulaire de
                    connexion ou cliquer sur le lien suivant:
                </p>
                <div class="w-100 text-center mt-3">
                    <router-link class="text-white btn btn-danger" to="/login">
                        Se connecter</router-link
                    >
                </div>
            </b-col>
        </b-container>
    </div>
</template>

<script>
import router from "../../router";
import authenticationService from "../../services/authenticationService";

export default {
    name: "register",
    data() {
        return {
            load: false,
            step: 1,
            form: {
                telephone: "",
                code_domatel: "",
                code_sms: "",
                password: "",
                password_confirmation: "",
            },
            formValidation: {
                telephone: true,
                code_domatel: true,
                code_sms: true,
                password: true,
                password_confirmation: true,
            },
        };
    },
    methods: {
        getLogoUrl() {
            if (!location.host.includes("family")) {
                return "/img/logo.png";
            } else {
                return "/img/gfs_logo_2.png";
            }
        },
        getBaselineUrl() {
            if (!location.host.includes("family")) {
                return "/img/baseline.png";
            } else {
                return "/img/gfs_baseline.png";
            }
        },
        validationTelephone() {
            this.formValidation.telephone = this.form.telephone.length > 0;
            return this.formValidation.telephone;
        },
        validationDomatel() {
            this.formValidation.code_domatel =
                this.form.code_domatel.length > 0;
            return this.formValidation.code_domatel;
        },
        validationVerificationCode() {
            this.formValidation.code_sms = this.form.code_sms.length > 0;
            return this.formValidation.code_sms;
        },
        validationPassword() {
            this.formValidation.password = this.form.password.length > 6;
            return this.formValidation.password;
        },
        validationPasswordConfirm() {
            this.formValidation.password_confirmation =
                this.form.password_confirmation.length > 0 &&
                this.form.password_confirmation === this.form.password;
            return this.formValidation.password_confirmation;
        },
        submit1(event) {
            this.load = true;
            event.preventDefault();
            this.validationTelephone();
            this.validationDomatel();
            if (
                this.formValidation.telephone === true &&
                this.formValidation.code_domatel === true
            ) {
                authenticationService
                    .sendVerificationCode(this.form)
                    .then((response) => {
                        if (response.data.etat === "OK") {
                            this.step = 2;
                        } else {
                            this.$rollbar.info("Register error", {
                                label: "Register error",
                                username: this.form.telephone,
                                code: this.form.code_domatel,
                                details: response.data.data,
                                useragent: navigator.userAgent,
                            });

                            this.$bvToast.toast(response.data.data, {
                                title: "Erreur 3008",
                                autoHideDelay: 5000,
                                variant: "danger",
                            });
                        }
                        this.load = false;
                    })
                    .catch((error) => {
                        this.$rollbar.error("Erreur 3009", error);
                        this.$bvToast.toast(
                            "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.",
                            {
                                title: "Erreur 3009",
                                autoHideDelay: 5000,
                                variant: "danger",
                            }
                        );
                        this.load = false;
                    });
            } else {
                this.load = false;
            }
        },
        submit2(event) {
            this.load = true;
            event.preventDefault();
            this.validationVerificationCode();
            if (this.formValidation.code_sms === true) {
                authenticationService
                    .sendSMSCode(this.form)
                    .then((response) => {
                        if (response.data.etat === "OK") {
                            this.step = 3;
                        } else {
                            this.$rollbar.info("Sms code error", {
                                label: "Sms code error",
                                username: this.form.telephone,
                                useragent: navigator.userAgent,
                            });

                            this.$bvToast.toast("Le code SMS est erroné", {
                                title: "Erreur 3010",
                                autoHideDelay: 5000,
                                variant: "danger",
                            });
                        }
                        this.load = false;
                    })
                    .catch((error) => {
                        this.$rollbar.error("Erreur 3011", error);
                        this.$bvToast.toast(
                            "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.",
                            {
                                title: "Erreur 3011",
                                autoHideDelay: 5000,
                                variant: "danger",
                            }
                        );
                        this.load = false;
                    });
            } else {
                this.load = false;
            }
        },
        sendVerificationCode() {
            this.load = true;
            authenticationService
                .resendVerificationCode(this.form)
                .then((response) => {
                    if (response.data.etat === "OK") {
                        this.$bvToast.toast(
                            "Le sms vient d'être envoyé avec succès",
                            {
                                title: "SMS",
                                autoHideDelay: 5000,
                                variant: "success",
                            }
                        );
                    } else {
                        this.$rollbar.error("Erreur 3012");
                        this.$bvToast.toast(response.data.data, {
                            title: "Erreur 3012",
                            autoHideDelay: 5000,
                            variant: "danger",
                        });
                    }
                    this.load = false;
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3013", error);
                    this.$bvToast.toast(
                        "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.",
                        {
                            title: "Erreur 3013",
                            autoHideDelay: 5000,
                            variant: "danger",
                        }
                    );
                    this.load = false;
                });
        },
        submit3(event) {
            this.load = true;
            event.preventDefault();
            this.validationPassword();
            this.validationPasswordConfirm();
            if (
                this.formValidation.password === true &&
                this.formValidation.password_confirmation === true
            ) {
                authenticationService
                    .register(this.form)
                    .then((response) => {
                        if (response.data.etat === "OK") {
                            this.step = 4;
                            setTimeout(() => {
                                router.push({ name: "login" });
                            }, 10000);
                        } else {
                            this.$rollbar.error("Erreur 3014");
                            this.$bvToast.toast(response.data.data, {
                                title: "Erreur 3014",
                                autoHideDelay: 5000,
                                variant: "danger",
                            });
                        }
                        this.load = false;
                    })
                    .catch((error) => {
                        this.$rollbar.error("Erreur 3015", error);
                        this.$bvToast.toast(
                            "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.",
                            {
                                title: "Erreur 3015",
                                autoHideDelay: 5000,
                                variant: "danger",
                            }
                        );
                        this.load = false;
                    });
            } else {
                this.load = false;
            }
        },
    },
};
</script>

<style scoped>
#login-page #billboard {
    position: absolute;
    width: 100%;
    left: 0;
}

#login-page #login-form {
    margin-top: 16em;
}

#sendSms {
    font-size: 16px;
}

@media (min-width: 992px) {
    #login-page #login-form {
        margin-top: 18em;
    }
}
</style>
