import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    sendVerificationCode(form) {
        return window.axios.post(this.baseUrl + 'api/register/send-code-verif', form)
    },
    resendVerificationCode(form) {
        return window.axios.post(this.baseUrl + 'api/register/resend-code-verif', form)
    },
    sendSMSCode(form) {
        return window.axios.post(this.baseUrl + 'api/register/valide-code-sms', form)
    },
    register(form) {
        return window.axios.post(this.baseUrl + 'api/register/create-user', form)
    },
    changePassword(form) {
        return window.axios.post(this.baseUrl + 'api/register/update-password', form)
    },
    impersonate(id) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/auth/' + id + '/impersonate', {}, config);
    },
}
